<template>
  <div>
    <div class="headDetail">
      <div class="detailQuery mb20">
        <div>
          <span> {{ searchObj.park }}</span>
        </div>
        <div>
          <span> {{ searchObj.dataPacket }}</span>
        </div>
        <div>
          上传时间: <span> {{ searchObj.date }}</span>
        </div>
      </div>

      <el-select
        v-model="searchObj.dataType"
        placeholder="请选择数据类型"
        class="centerSelInput ml20"
      >
        <el-option
          v-for="item in dataTypeList"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <button class="centerCzbtn ml20">搜索</button>
    </div>

    <Table
      :data1="tableData"
      :columns1="columns"
      :total="10"
      @getAjax="getTableData"
      :loading="loading"
    />

    <!-- 分配企业 -->
    <el-drawer title="查看详情" :visible.sync="isViewDetails" direction="rtl">
      <div class="demo-drawer__content lookDetail">
        <div class="parkName">
          <div>园区名称：<span> 园区一</span></div>
          <div>报文名称：<span> 报文一</span></div>
          <div>报文时间：<span> 2020.02.09 13:49:52</span></div>
        </div>

        <el-input
          type="textarea"
          class="viewTextarea"
          :rows="6"
          placeholder="请输入内容"
          v-model="textarea"
        >
        </el-input>

        <div class="transformation">
          <img src="@/assets/images/transformation.png" alt="" />
          <span>转换</span>
        </div>

        <el-table :data="tableData1" style="width: 100%">
          <el-table-column prop="dataName" label="数据名称"> </el-table-column>
          <el-table-column prop="dataType" label="数据类型"> </el-table-column>
          <el-table-column prop="value" label="值"> </el-table-column>
        </el-table>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Table from "@/components/Table.vue";

export default {
  components: {
    Table,
  },
  data() {
    return {
      searchObj: {
        park: "",
        dataPacket: "",
        date: "",
      },
      textarea:
        "报文内容",
      loading: false,
      isViewDetails: false,
      dataTypeList: [],
      tableData: [],
      columns: [
        {
          label: "名称",
          prop: "dataName",
        },
        {
          label: "类型",
          prop: "messageTypeStr",
        },
        {
          label: "报文时间",
          prop: "messageDate",
        },

        // {
        //     label: "操作",
        //     // fixed: "right",
        //     width: 250,
        //     render: (h, params) => {
        //         return h("div", [

        //             h(
        //                 "span", {
        //                     class: "operationA",
        //                     on: {
        //                         click: () => {
        //                             // this.isViewDetails = true
        //                         },
        //                     },
        //                 },
        //                 "查看详情"
        //             )
        //         ]);
        //     },
        // }
      ],
      tableData1: [
        {
          dataName: "数据名称",
          dataType: sessionStorage.getItem("dataTypeStr"),
          value: "123456",
        },
        {
          dataName: "数据名称",
          dataType: sessionStorage.getItem("dataTypeStr"),
          value: "123456",
        },
        {
          dataName: "数据名称",
          dataType: sessionStorage.getItem("dataTypeStr"),
          value: "123456",
        },
        {
          dataName: "数据名称",
          dataType: sessionStorage.getItem("dataTypeStr"),
          value: "123456",
        },
      ],
    };
  },
  mounted() {
    this.getTableData();
  },
  methods: {
    //造假数据
    getTableData() {
      this.tableData = [
        {
          dataName: "车辆报文",
          messageTypeStr: sessionStorage.getItem("dataTypeStr"),
          messageDate: "2021.10.01 08:49:12",
        },
        {
          dataName: "can数据",
          messageTypeStr: sessionStorage.getItem("dataTypeStr"),
          messageDate: "2021.10.01 08:59:45",
        },
        {
          dataName: "车辆报文",
          messageTypeStr: sessionStorage.getItem("dataTypeStr"),
          messageDate: "2021.10.01 09:09:43",
        },
        {
          dataName: "车辆报文",
          messageTypeStr: sessionStorage.getItem("dataTypeStr"),
          messageDate: "2021.10.01 09:19:23",
        },
        {
          dataName: "rsu数据",
          messageTypeStr: sessionStorage.getItem("dataTypeStr"),
          messageDate: "2021.10.01 09:49:34",
        },
        {
          dataName: "rsu数据",
          messageTypeStr: sessionStorage.getItem("dataTypeStr"),
          messageDate: "2021.10.01 09:59:34",
        },
        {
          dataName: "can数据",
          messageTypeStr: sessionStorage.getItem("dataTypeStr"),
          messageDate: "2021.10.01 10:09:21",
        },
        {
          dataName: "车辆报文",
          messageTypeStr: sessionStorage.getItem("dataTypeStr"),
          messageDate: "2021.10.01 10:19:34",
        },
      ];
      this.searchObj.park = sessionStorage.getItem("parkName");
      this.searchObj.dataPacket = sessionStorage.getItem("data");
      this.searchObj.date = sessionStorage.getItem("dataTwo");
    },
  },
};
</script>

<style lang="less">
.headDetail {
  display: flex;
}

.detailQuery {
  height: 36px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  color: #666;
  font-size: 14px;
}

.detailQuery > div {
  margin-left: 40px;
}

.detailQuery span {
  color: #333;
  font-size: 14px;
  font-weight: 550;
  margin-left: 5px;
}

.parkName {
  color: #666666;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
}

.parkName span {
  color: #333333;
  font-weight: 550;
}

.parkName > div {
  min-width: 50%;
  margin-bottom: 30px;
}
.viewTextarea .el-textarea__inner {
  background: #f5f8fa;
  border: 0px;
  font-size: 14px;
  color: #333;
}
.transformation {
  margin: 40px auto;
  height: 36px;
  width: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(22, 131, 217, 0.1);
  font-size: 14px;
  color: #1683d9;
}
.lookDetail {
  .has-gutter > tr > th {
    background: #f5f8fa;
  }
}
</style>